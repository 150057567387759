import React from "react";
import "./home.css";

function home() {
  return (
    <div className="main_wrapper" id="Home">
      <h1>AV8TRIX</h1>
      <p>Crafting Dreams, Soaring Realities!</p>
      <img src="/team.jpg" alt="" />
{/*       <div className="circle-1"></div> */}
    </div>
  );
}

export default home;
